import { configuredAxios } from './configuredAxios';
import { appConfig } from 'app/app.config';
import {
  BackendForm,
  SubmitLdmParametersFormRequest,
  SubmitLdmParametersFormResponseBody,
} from './genTypes/dto';

export const ldmAPI = {
  getLdmParametersForm: (): Promise<BackendForm> => {
    return configuredAxios
      .get<BackendForm>(appConfig.ldmParametersFormEndpoint)
      .then((response) => {
        return response.data;
      });
  },
  setParameters: (
    changedValues: SubmitLdmParametersFormRequest
  ): Promise<SubmitLdmParametersFormResponseBody> => {
    return configuredAxios
      .post<SubmitLdmParametersFormResponseBody>(appConfig.ldmParametersFormEndpoint, changedValues)
      .then((response) => {
        return response.data;
      });
  },
};
