import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import Text from '@ingka/text';
import { LdmViewParameters } from './components/LdmViewParameters/LdmViewParameters';
import { useUserRoles } from 'hooks/useUserRoles';
import { useTranslate } from 'hooks/useTranslate';
import { WarningAlert } from 'components/alerts';

const tabs = {
  assignments: {
    key: '0',
    panelId: 'ldm-view-tab-assignments',
    tabId: 'ldm-view-tab-assignments-tab',
    label: 'ldmViewAssignments',
  },
  parameters: {
    key: '1',
    panelId: 'ldm-view-tab-parameters',
    tabId: 'ldm-view-tab-parameters-tab',
    label: 'ldmViewParameters',
  },
} as const;

export function LdmView() {
  const userRoles = useUserRoles();
  const t = useTranslate();

  if (!userRoles.isLDM) {
    return <Text>{t('unauthorized')}</Text>;
  }

  return (
    <Tabs
      defaultActiveTab={tabs.assignments.panelId}
      onTabChanged={function noRefCheck() {}}
      tabPanels={[
        <TabPanel key={tabs.assignments.key} tabPanelId={tabs.assignments.panelId}>
          <WarningAlert
            title="Not implemented"
            message="Assignments functionality has not yet been implemented."
          />
        </TabPanel>,
        <TabPanel key={tabs.parameters.key} tabPanelId={tabs.parameters.panelId}>
          <LdmViewParameters />
        </TabPanel>,
      ]}
      tabs={Object.keys(tabs).map((key) => {
        const tab = tabs[key as keyof typeof tabs];
        return <Tab key={tab.key} id={tab.tabId} tabPanelId={tab.panelId} text={t(tab.label)} />;
      })}
    />
  );
}
