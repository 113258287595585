import Button from '@ingka/button';
import { Select, Option } from '@ingka/select';
import { useTranslate } from 'hooks/useTranslate';
import {
  NCAControlsStateForActions,
  NCAControlsStateForReasonCodes,
  NCAControlsStateForSubmitting,
  NCAControlsStateForCausingParties,
} from '../../NCAControls.types';
import { Confirmation } from 'components/common/Confirmation/Confirmation';
import styled from 'styled-components';
import { Row, Stack, StackSpaced } from 'components/layout';
import { InputSelect } from 'components/InputSelect';

const ButtonFullWidth = styled(Button)`
  width: 100%;
`;
const RowButtons = styled(Stack)`
  justify-content: flex-end;
`;

// TODO: This component should be broken up to make unit testing possible/easier
export function NCAControlsActionSelect({
  isAnyClaimActionPossible,
  actionState,
  reasonCodeState,
  causingPartyState,
  submittingState,
  onDeselect,
}: {
  isAnyClaimActionPossible?: boolean;
  actionState: NCAControlsStateForActions;
  reasonCodeState: NCAControlsStateForReasonCodes;
  causingPartyState: NCAControlsStateForCausingParties;
  submittingState: NCAControlsStateForSubmitting;
  onDeselect?: () => void;
}) {
  const t = useTranslate();

  return (
    <StackSpaced>
      {/* Select: Action */}
      <Select
        id="NCAControlsActionSelectId"
        data-testid="NCAControlsActionSelectId"
        label={t('selectAction')}
        multiple={false}
        value={actionState.action}
        onChange={(e) => actionState.setAction(e.target.value)}
        disabled={!isAnyClaimActionPossible || submittingState.isSubmitting}
      >
        {/* <Option id="action-noValue" name="-" value={''} /> */}
        {actionState.actionOptions.map((action) => (
          <Option
            key={'nca-controls-action-' + action.key}
            id={action.key}
            name={t(action.key)}
            value={action.key}
            disabled={action.disabled}
          />
        ))}
      </Select>

      {/* Select: Causing Party */}
      <InputSelect
        id="NCAControlsCausingPartySelectId"
        dataTestId="NCAControlsCausingPartySelectId"
        label={t('causingParty')}
        multiple={false}
        value={causingPartyState.causingParty}
        onChange={(e) => causingPartyState.setCausingParty(e)}
        disabled={causingPartyState.isCausingPartyDropdownDisabled || submittingState.isSubmitting}
        options={causingPartyState.causingPartyOptions.map((causingParty) => ({
          label: causingParty.name,
          value: causingParty.businessUnit,
        }))}
      ></InputSelect>

      {/* Select: Reason Code */}
      <Select
        id="NCAControlsReasonCodeSelectId"
        data-testid="NCAControlsReasonCodeSelectId"
        label={t('reasonCode')}
        multiple={false}
        value={reasonCodeState.reasonCode}
        onChange={(e) => reasonCodeState.setReasonCode(e.target.value)}
        disabled={reasonCodeState.isReasonCodesDropdownDisabled || submittingState.isSubmitting}
      >
        {/* <Option id="reasonCode-noValue" name="-" value={''} /> */}
        {reasonCodeState.reasonCodeOptions.map((reasonCode) => (
          <Option
            key={'reasonCode-' + reasonCode.code}
            id={reasonCode.code}
            name={reasonCode.message}
            value={reasonCode.code}
          />
        ))}
      </Select>

      {/* Submit changes */}

      <RowButtons>
        <Row>
          {onDeselect && (
            <Button
              type="secondary"
              disabled={submittingState.isSubmitting}
              onClick={() => {
                onDeselect();
              }}
            >
              {t('ncaMultiSelectButtonDeselect')}
            </Button>
          )}

          <Confirmation
            handleOnConfirmed={submittingState.handleOnSubmit}
            triggerElement={
              <ButtonFullWidth
                type="emphasised"
                disabled={submittingState.isSubmitDisabled}
                loading={submittingState.isSubmitting}
              >
                {t('saveAndSubmit')}
              </ButtonFullWidth>
            }
            disabled={submittingState.isSubmitDisabled || submittingState.isSubmitting}
          />
        </Row>
      </RowButtons>
    </StackSpaced>
  );
}
