//Deploy to feature #1
import { API_QUERY_STALE_TIME } from 'app/app.constants';
import { getTemplatedAppNameOrDefault } from 'utils/getTemplatedAppNameOrDefault';

declare var window: any;

export const appConfig = {
  appName: getTemplatedAppNameOrDefault('Extra Cost Handling'),
  customerEndpoint:
    window?.env?.CUSTOMER_BACKEND_ENDPOINT || process.env.REACT_APP_CUSTOMER_BACKEND_ENDPOINT,
  /** API endpoint used to GET one or many NCAs as well as to POST updates to an NCA. */
  ncaEndpoint: window?.env?.API_ENDPOINT_NCA || process.env.REACT_APP_API_ENDPOINT_NCA,
  attachmentsEndpoint:
    window?.env?.API_ENDPOINT_ATTACHMENTS || process.env.REACT_APP_API_ENDPOINT_ATTACHMENTS,
  updateManyNCAEndpoint:
    window?.env?.UPDATE_MANY_NCA_ENDPOINT || process.env.REACT_APP_UPDATE_MANY_NCA_ENDPOINT,
  configurationsEndpoint:
    window?.env?.API_ENDPOINT_CONFIGURATIONS || process.env.REACT_APP_API_ENDPOINT_CONFIGURATIONS,
  businessUnitsEndpoint:
    window?.env?.API_ENDPOINT_BUSINESS_UNITS || process.env.REACT_APP_API_ENDPOINT_BUSINESS_UNITS,
  ldmParametersFormEndpoint:
    window?.env?.API_ENDPOINT_LDM_PARAMETERS_FORM ||
    process.env.REACT_APP_API_ENDPOINT_LDM_PARAMETERS_FORM,
  appClientId: window?.env?.CLIENT_ID || process.env.REACT_APP_CLIENT_ID,
  appTenantId: window?.env?.TENANT_ID || process.env.REACT_APP_TENANT_ID,
  appScope: window?.env?.SCOPE || process.env.REACT_APP_SCOPE,
  appInsightKey: window?.env?.INSIGHTS_KEY || process.env.REACT_APP_INSIGHTS_KEY,
  /** The app version. */
  version: 'v0.22',
  /** Default stale time for the app. */
  staleTime: API_QUERY_STALE_TIME,
};

if (!Object.values(appConfig).every((x) => x !== null && x !== '' && x !== undefined)) {
  throw Error(`Missing value for env variables in env-config`);
}
