import { BusinessUnitSchema, NCADto, UpdateManyNCAsResponse } from 'api/genTypes/dto';
import { ncaAPI } from 'api/ncaAPI';
import { useNcaDetails } from 'pages/nca/components/NCADetails/hooks/useNcaDetails';
import { useState } from 'react';
import {
  NCAControlsStateForSubmitting,
  UseNCAControlsResult,
  UseNCAControlsFormState,
} from '../NCAControls.types';
import { NcaActions } from 'pages/nca/constants/ncaActions';
import { ConfigurationRejectionReasonCode } from 'hooks/useConfigurations.types';
import { useRejectionReasonCodes } from 'hooks/useConfigurations';
import { queryClient } from 'app/app.providers';
import { useCausingParties } from 'hooks/useBusinessUnits';
import { useNCAList } from '../../NCAList/hooks/useNCAList';
import { useAppNotifications } from 'app/app.notifications';
import { useTranslate } from 'hooks/useTranslate';

export function useNCAControlsForSubmitting(
  formState: UseNCAControlsFormState,
  resetFormState: () => void,
  setShowResolutionCommentError: (state: boolean) => void,
  nca: NCADto[],
  onNotificationSubmission: (data: UpdateManyNCAsResponse) => void,
  many: boolean
): NCAControlsStateForSubmitting {
  const { showSuccessNotification, showErrorNotification, showWarningNotification } =
    useAppNotifications();
  const t = useTranslate();
  // Used to reload the data when we update
  const { refetch } = useNcaDetails();
  const { refetch: refetchList } = useNCAList();
  const [ncaResponse, setNcaResponse] = useState<UpdateManyNCAsResponse | undefined>(undefined);
  const resetNcaResponse = () => setNcaResponse(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSuccessfulUpdate = () => {
    // Show success notification.
    showSuccessNotification(t('claimUpdated'));
    setShowResolutionCommentError(false);

    // Reset action, causingParty, reasonCode and resolution comment if we successfully update.
    resetFormState();
  };
  const onFailedUpdate = () => {
    // If we fail to update, we show a failure notification,
    // but we do not reset any controls since we expect the user
    // may want to try submitting again without losing their input.
    showErrorNotification(t('claimUpdateFailed'));
    setShowResolutionCommentError(true);
  };

  // Handle submitting data and the side-effects thereof
  const handleOnSubmit = () => {
    if (formState.isSubmitDisabled) {
      // If for some reason we manage to submit without the submit action being allowed,
      // prevent it and show an error message.
      showErrorNotification(t('claimUpdateFailed'));

      return;
    }
    setIsSubmitting(true);
    if (!many) {
      ncaAPI
        .updateOneNCA({
          nonConformityAdviceId: nca[0]?.nonConformityAdviceId,
          requested_action: formState.action,
          causing_party: formState.causingParty,
          reasonCode: formState.reasonCode,
          resolutionComment: formState.resolutionComment,
        })
        .then(() => {
          onSuccessfulUpdate();
        })
        .catch((e) => {
          onFailedUpdate();
        })
        .finally(() => {
          setIsSubmitting(false);
          // Reload the current NCADetails page
          refetch();

          // Invalidate the NCAList query, so that the next time we open NCAList,
          // we re-load the list with the latest info.
          queryClient.invalidateQueries({ queryKey: 'NCA_OVERVIEW_QUERY' });
        });
    } else {
      ncaAPI
        .updateManyNCAs({
          nonConformityAdviceIds: nca?.map((item) => item.nonConformityAdviceId),
          requested_action: formState.action,
          causing_party: formState.causingParty,
          reasonCode: formState.reasonCode,
          resolutionComment: formState.resolutionComment,
        })
        .then((data) => {
          setNcaResponse(data);
          if (data.failed.length > 0) {
            if ([...data.approved, ...data.rejected, ...data.sent_to_ldm].length > 0) {
              showWarningNotification(t('someClaimsUpdate'));
            } else {
              showErrorNotification(t('claimUpdateFailed'));
            }
          } else {
            onSuccessfulUpdate();
          }
          onNotificationSubmission(data);
        })
        .catch((e) => {
          onFailedUpdate();
        })
        .finally(() => {
          setIsSubmitting(false);
          // Reload the current NCADetails page
          refetchList();
        });
    }
  };

  return {
    handleOnSubmit,
    ncaResponse,
    resetNcaResponse,
    isSubmitting,
  };
}

export const calculateIsSubmitDisabled = (
  action: string,
  reasonCode: string,
  causingParty: string,
  resolutionComment: string
) => {
  // Action statuses
  const isNoActionSelected: boolean = !action;
  const isRejectActionSelected: boolean = action === NcaActions.Reject;
  const isApproveActionSelected: boolean = action === NcaActions.Approve;

  // Reason code statuses
  const isNoReasonCodeSelected: boolean = !reasonCode;
  const isNoReasonCodeSelectedButRequired: boolean =
    isNoReasonCodeSelected && isRejectActionSelected;

  // Causing party statuses
  const isNoCausingPartySelected: boolean = !causingParty;
  const isNoCausingPartyProvidedButRequired: boolean =
    isNoCausingPartySelected && isApproveActionSelected;

  // Resolution comment statuses
  const isNoResolutionCommentProvided: boolean = !resolutionComment || resolutionComment.length < 3;

  // Submit statuses
  const isSubmitDisabled: boolean =
    isNoActionSelected ||
    isNoReasonCodeSelectedButRequired ||
    isNoResolutionCommentProvided ||
    isNoCausingPartyProvidedButRequired;

  return isSubmitDisabled;
};

export function useNCAControls(
  nca: NCADto[],
  onNotificationSubmission?: (data: UpdateManyNCAsResponse) => void,
  many?: boolean
): UseNCAControlsResult {
  const isAcceptActionAllowed: boolean =
    nca.length === 1
      ? nca[0].isAcceptActionAllowed
      : nca.every((item) => {
          return item.isAcceptActionAllowed || item.isSendToLdmActionAllowed;
        });
  const isRejectActionAllowed: boolean = nca.every((item) => item.isRejectActionAllowed === true);
  const isSendToLdmActionAllowed: boolean = nca.every(
    (item) => item.isSendToLdmActionAllowed === true
  );
  const isAnyClaimActionPossible: boolean =
    isAcceptActionAllowed || isRejectActionAllowed || isSendToLdmActionAllowed;

  const actionOptions: { key: string; disabled?: boolean }[] = [
    {
      key: NcaActions.Approve,
      disabled: !isAcceptActionAllowed,
    },
    {
      key: NcaActions.Reject,
      disabled: !isRejectActionAllowed,
    },
    {
      key: NcaActions.SendToManagerApproval,
      disabled: !isSendToLdmActionAllowed,
    },
  ];
  const causingPartyOptions: BusinessUnitSchema[] = useCausingParties();
  const reasonCodeOptions: ConfigurationRejectionReasonCode[] = useRejectionReasonCodes();

  // Multiple claims default to ''
  const resolutionCommentDefaultValue: string =
    nca.length === 1 ? nca[0]?.resolutionNotes || '' : '';

  const initialFormState = {
    action: '',
    causingParty: '',
    reasonCode: '',
    resolutionComment: resolutionCommentDefaultValue,
    isReasonCodesDropdownDisabled: true,
    isCausingPartyDropdownDisabled: true,
    showResolutionCommentError: false,
    isSubmitDisabled: true,
  };

  // Create states
  const [formState, setFormState] = useState<UseNCAControlsFormState>(initialFormState);

  // Action
  const isActionDropdownDisabled: boolean = !isAnyClaimActionPossible;
  const setAction = (action: string) => {
    setFormState({
      ...formState,
      action: action,
      reasonCode: action !== NcaActions.Reject ? '' : formState.reasonCode,
      causingParty: action !== NcaActions.Approve ? '' : formState.causingParty,
      isReasonCodesDropdownDisabled: action !== NcaActions.Reject || !isAnyClaimActionPossible,
      isCausingPartyDropdownDisabled: action !== NcaActions.Approve || !isAnyClaimActionPossible,
      isSubmitDisabled: calculateIsSubmitDisabled(
        action,
        formState.reasonCode,
        formState.causingParty,
        formState.resolutionComment
      ),
    });
  };
  const resetFormState = () => setFormState(initialFormState);
  const resetAction = () => setAction('');
  // Causing Party
  const setCausingParty = (causingParty: string) =>
    setFormState({
      ...formState,
      causingParty: causingParty,
      isSubmitDisabled: calculateIsSubmitDisabled(
        formState.action,
        formState.reasonCode,
        causingParty,
        formState.resolutionComment
      ),
    });
  const resetCausingParty = () => setCausingParty('');

  // Reason Code
  const setReasonCode = (reasonCode: string) =>
    setFormState({
      ...formState,
      reasonCode: reasonCode,
      isSubmitDisabled: calculateIsSubmitDisabled(
        formState.action,
        reasonCode,
        formState.causingParty,
        formState.resolutionComment
      ),
    });
  const resetReasonCode = () => setReasonCode('');

  // Resolution Comment
  const setResolutionComment = (resolutionComment: string) =>
    setFormState({
      ...formState,
      resolutionComment: resolutionComment,
      isSubmitDisabled: calculateIsSubmitDisabled(
        formState.action,
        formState.reasonCode,
        formState.causingParty,
        resolutionComment
      ),
    });
  const resetResolutionComment = () => setResolutionComment(resolutionCommentDefaultValue);
  const setShowResolutionCommentError = (showError: boolean) =>
    setFormState({
      ...formState,
      showResolutionCommentError: showError,
    });
  // Don't allow resolution comment to be edited if the claim cannot be submitted.
  // Otherwise, allow it.
  const isResolutionCommentDisabled: boolean = !isAnyClaimActionPossible;

  const submittingState = useNCAControlsForSubmitting(
    formState,
    resetFormState,
    setShowResolutionCommentError,
    nca,
    onNotificationSubmission,
    many
  );

  return {
    isAnyClaimActionPossible: isAnyClaimActionPossible,
    resetFormState: resetFormState,
    actionState: {
      action: formState.action,
      setAction: setAction,
      resetAction: resetAction,
      isActionDropdownDisabled: isActionDropdownDisabled,
      actionOptions: actionOptions,
    },
    reasonCodeState: {
      reasonCode: formState.reasonCode,
      setReasonCode: setReasonCode,
      resetReasonCode: resetReasonCode,
      isReasonCodesDropdownDisabled: formState.isReasonCodesDropdownDisabled,
      reasonCodeOptions: reasonCodeOptions,
    },
    causingPartyState: {
      causingParty: formState.causingParty,
      setCausingParty: setCausingParty,
      resetCausingParty: resetCausingParty,
      isCausingPartyDropdownDisabled: formState.isCausingPartyDropdownDisabled,
      causingPartyOptions: causingPartyOptions,
    },
    resolutionCommentState: {
      resolutionComment: formState.resolutionComment,
      setResolutionComment: setResolutionComment,
      resetResolutionComment: resetResolutionComment,
      isResolutionCommentDisabled: isResolutionCommentDisabled,
      showResolutionCommentError: formState.showResolutionCommentError,
      setShowResolutionCommentError: setShowResolutionCommentError,
    },
    submittingState: {
      handleOnSubmit: submittingState.handleOnSubmit,
      isSubmitDisabled: formState.isSubmitDisabled,
      ncaResponse: submittingState.ncaResponse,
      resetNcaResponse: submittingState.resetNcaResponse,
      isSubmitting: submittingState.isSubmitting,
    },
  };
}
