import './LdmViewParameters.css';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { DataContentWrapper } from 'components/common/DataWrapper';
import { useTranslate } from 'hooks/useTranslate';
import { useAppNotifications } from 'app/app.notifications';
import { ldmAPI } from 'api/ldmApi';
import { Row, Stack } from 'components/layout';
import Button from '@ingka/button';
import styled from 'styled-components';
import { BackendFormValues, BackendFormResult } from 'components/BackendForm/BackendForm.types';
import { BackendFormUI } from 'components/BackendForm/BackendFormUI';
import { useBackendForm } from 'components/BackendForm/hooks/useBackendForm';
import { Confirmation } from 'components/common/Confirmation/Confirmation';

export function handleSubmit(
  changes: BackendFormValues,
  query: { refetch: () => void },
  setIsSubmitParametersLoading: (loading: boolean) => void,
  api: typeof ldmAPI,
  notifications: {
    showSuccessNotification: (msg: string) => void;
    showWarningNotification: (msg: string) => void;
    showErrorNotification: (msg: string) => void;
  },
  t: ReturnType<typeof useTranslate>
): Promise<void> {
  setIsSubmitParametersLoading(true);
  return api
    .setParameters({
      controls: changes,
    })
    .then((response) => {
      if (response.failedFieldUpdates.length > 0) {
        notifications.showWarningNotification(t('form.result.warning'));
      } else {
        notifications.showSuccessNotification(t('form.result.success'));
      }
      query.refetch();
    })
    .catch(() => {
      notifications.showErrorNotification(t('form.result.error'));
    })
    .finally(() => {
      setIsSubmitParametersLoading(false);
    });
}

export function useLdmViewSystemParameters() {
  // Hooks
  const t = useTranslate();
  const notifications = useAppNotifications();

  // State
  const query = useQuery({
    queryKey: ['system-parameters'],
    queryFn: () => ldmAPI.getLdmParametersForm(),
  });
  const form: BackendFormResult = useBackendForm(query.data);
  const [isSubmitParametersLoading, setIsSetParametersLoading] = useState<boolean>(false);

  // Result
  return {
    form,
    submit: async () =>
      handleSubmit(form.changedValues, query, setIsSetParametersLoading, ldmAPI, notifications, t),
    isLoading: query.isLoading || isSubmitParametersLoading,
  };
}

const FormStack = styled(Stack)`
  align-items: flex-start;
  gap: 2rem;
`;

export function LdmViewParameters() {
  // Hooks
  const t = useTranslate();
  const { form, submit, isLoading } = useLdmViewSystemParameters();

  return (
    <DataContentWrapper
      isLoading={isLoading}
      data={form && form.controls.length > 0}
      error={undefined}
    >
      <FormStack>
        <BackendFormUI form={form} />
        <Row>
          {form.isChanged && (
            <Confirmation
              handleOnConfirmed={submit}
              triggerElement={
                <Button
                  type="emphasised"
                  onClick={submit}
                  disabled={!form.isChanged || !form.valid}
                  loading={isLoading}
                  text={t('form.submit')}
                />
              }
              disabled={!form.isChanged || !form.valid}
            />
          )}
        </Row>
      </FormStack>
    </DataContentWrapper>
  );
}
